/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import growth from '@elegantstack/gatsby-theme-flexiblocks/src/homepage/consulting/assets/growth.svg';

export default {
  featuresContainer: {
    position: `relative`,
    py: [5, 6],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: 0,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1,
      borderRadius: `xl`,
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 80%,
          ${t.colors.omegaLight} 100%
        )`
    }
  },
  testimonialsContainer: {
    position: `relative`,
    py: [5, 6],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: [`full`, `90%`],
      height: `78%`,
      top: 0,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: t => `linear-gradient(
          25deg,
          ${t.colors.omegaDark} 50%,
          ${t.colors.omegaDarker} 100%
        )`
    }
  },
  companiesContainer: {
    background: 'white',
  },
  contentTwoContainer: {
    bg: `omegaDarker`,
    borderRadius: `xl`,
    py: [4, 5],
    px: [4, 0]
  },
  getStartedContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: [`25%`, 0],
      right: 6,
      zIndex: -1,
      background: `url(${growth}) no-repeat right 0`,
      backgroundSize: `55%`
    }
  },
  potentialCustomersContainer: {
    bg: `omegaLighter`,
    borderRadius: `xl`,
    py: 5,
    px: [4, 0]
  }
}
