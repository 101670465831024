import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentImages from '@solid-ui-components/ContentImages'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const Integrations = ({ content }) => {
  const { text, collection, buttons } = content

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text} />
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
          <Divider space={3} />
        </>
      )}
      <Flex>
        {collection?.map(({ images }, index) => (
          <Box
            variant='cards.paper'
            key={`item-${index}`}
            sx={{
              flexGrow: 1,
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '5px'
            }}
          >
              <ContentImages
                content={{ images }}
                sx={{
                  maxWidth: '200px'
                }}
              />
          </Box>
        ))}
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(Integrations)
