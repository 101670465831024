import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@widgets/Seo'
import Divider from '@solid-ui-components/Divider'
import ContentAlt from '@solid-ui-blocks/Content/Block01'
import HomePageHero from '../components/HomePageHero'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Integrations from '../components/Integrations'
import Features from '@solid-ui-blocks/Features/Block06'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Companies from '../components/Companies'
import CustomListBlock from '../components/CustomListBlock'
import Products from '../components/Products'
import ImpactSection from '../components/ImpactSection'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import GatsbyConfig from '../../gatsby-config'

const Index = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Review Management Software'
        description='Online review management software that is simple to set up, easy to use and delivers results. Perfect for busy small business owners.'
        keywords={['review monitoring', 'online reputation management', 'review generation', 'collect reviews', 'google my business', 'google reviews', 'quickbooks online', 'small business']}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      >
        <script type="application/ld+json">{`{
          "@context": "https://schema.org/",
          "@type": "WebApplication",
          "name": "Simple Review Management",
          "description": "We help businesses collect the reviews they have earned.",
          "applicationCategory": "BusinessApplication",
          "applicationSubCategory": "Digital Marketing",
          "browserRequirements": "Requires HTML5 and JavaScript support",
          "operatingSystem": "Windows, macOS, Android, iOS, Linux",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.4",
            "ratingCount": "7"
          }
        }`}</script>
      </Seo>
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <HomePageHero content={content['hero']} />
      <Divider space='5' />
      <ImpactSection content={content['review-management-impact']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.companiesContainer}>
        <Companies content={content['companies']} />
      </Container>
      <Divider space='5' />
      <Container variant='full' sx={styles.testimonialsContainer}>
        <Testimonials content={content['app-reviews']} />
      </Container>
      <Container variant='wide' sx={styles.featuresContainer}>
        <Features content={content['smb-appeal']} />
      </Container>
      <Divider space='5' />
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <ContentAlt content={content['pricing-explanation']} />
      </Container>
      <Divider space='5' />
      <Products content={content['products']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['how-we-help-stats']} reverse />
        <Divider space='5' />
        <Contact content={content['how-we-help-stats-cta']} />
      </Container>
      <Divider space='5' />
      <Container variant='wide' sx={styles.potentialCustomersContainer}>
        <CustomListBlock content={content['potential-customers']} />
      </Container>
      <Divider space='5' />
      <Integrations content={content['integrations']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}


export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Index
