import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const HomePageHero = ({
  content: { text = [], images, buttons },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          `column`,
          null,
          null,
          `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: `2/5`,
          mb: [4, null, null, null],
          textAlign: [`center`, null, null, `left`]
        }}
      >
        <ContentText content={text} />
        {buttons && (
          <>
            <Divider space={3} />
            <ContentButtons content={buttons} />
            <Divider space={3} />
          </>
        )}
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          ml: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          imagePosition='center'
        />
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(HomePageHero)
