import React from 'react'
import SVG from 'react-inlinesvg'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  logo: {
    width: [100, 150],
    height: [50, 75]
  }
}

const Companies = ({ content }) => {
  const { text, collection, buttons } = content

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text} />
      {text && collection && <Divider />}
      <Flex sx={{ flexWrap: `wrap`, m: -3 }}>
        {collection?.map(({ text, images }, index) => (
          <Box key={`item-${index}`} sx={{ flexGrow: 1, p: [1, 3] }}>
            <ContentImages
                content={{ images }}
                imagePosition='center'
                threshold={0.1}
                sx={{
                    width: '100px'
                }}
            />
          </Box>
        ))}
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(Companies)
