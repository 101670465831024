const fontFile = require('./src/@elegantstack/solid-ui-theme/typography-fonts.json')

module.exports = {
  flags: {
    DEV_SSR: false
  },
  plugins: [
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: "reviews-marketing-site",
        generateRedirectObjectsForPermanentRedirects: true,
        protocol: "https",
        hostname: "simplereviewmanagement.com",
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager-timeout',
      options: {
        id: 'GTM-KKC8NMG',
        includeInDevelopment: false,
      }
    },
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: "907654373797382",
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        excludes: [
          '/review-requests'
        ]
      }
    },
    {
      resolve: '@elegantstack/gatsby-theme-flexiblocks',
      options: {
        createDemoPages: false,
        colorMode: false,
        fonts: fontFile.fonts
      }
    },
    {
      resolve: '@elegantstack/gatsby-theme-flexiblog-minimal-v2',
      options: {
        basePath: '/blog',
        darkMode: false,
        localPaths: {
          post: 'content/blog'
        }
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Simple Review Management',
        short_name: 'Simple RM',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#f66b15',
        display: 'minimal-ui',
        icon: 'content/assets/favicon.png'
      }
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://simplereviewmanagement.com`,
      }
    },
    {
      resolve: 'gatsby-plugin-rollbar',
      options: {
        accessToken: '97cd36f08b994f658abe8cbf489cb77d',
        // For all configuration options, see https://docs.rollbar.com/docs/rollbarjs-configuration-reference
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: "production"
        }
      }
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        policy: [{
          userAgent: '*',
          allow: '/',
          disallow: [
            '/review-requests'
          ]
        }]
      }
    }
  ],
  // Customize your site metadata
  siteMetadata: {
    title: 'Simple RM',
    siteUrl: `https://simplereviewmanagement.com/`,
    author: 'Simple RM',
    name: 'Simple Review Management',
    description: 'Simple RM helps businesses collect the reviews they have earned',
    image: '/logo.png',
    address: '',
    email: 'support@simplereviewmanagement.com',
    phone: '',

    // Site Social Media Links - not being used anymore
    social: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/SimpleReviewManagement'
      }
    ],

    // Blog Header Menu Items
    headerMenu: [
      {
        name: 'Home',
        slug: '/'
      },
      {
        name: 'Blog',
        slug: '/blog/'
      },
      {
        name: 'Contact',
        slug: '/contact/'
      }
    ],

    //Footer Menu Items (2 Sets) - not being used anymore
    footerMenu: [
      {
        title: 'Quick Links',
        items: [
          {
            name: 'Contact Us',
            slug: '/contact/'
          }
        ]
      },
      {
        title: 'Legal Stuff',
        items: [
          {
            name: 'Privacy Policy',
            slug: '/privacy-policy/'
          }
        ]
      }
    ]
  }
}
